import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative rounded-3xl overflow-hidden shadow-2xl transition-transform transform hover:shadow-2xl group  bg-white">
      <img
        src={data.logo}
        alt={data.title}
        className="w-full h-64 object-cover hover:scale-105"
      />
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <Link to={`/single?slug=${data.slug}`} className="text-blue-600">
          <img src="/playIcon.png" alt="play" className="w-16" />
        </Link>
      </div>
      <h2 className="text-xl font-bold text-black my-5 text-center">
        {data.title}
      </h2>
    </div>
  );
}
