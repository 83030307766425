import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="p-2 bg-slate-500 text-white text-center text-md">
      <p className="my-1 ">
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            focusinstitution.xyz
          </Link>
        </span>
      </p>
    </footer>
  );
}
