import React from "react";

export default function Loader() {
  return (
    <section className="flex items-center justify-center p-8">
      <div className="relative w-[48px] h-[48px] mx-auto">
        <div className="absolute w-[48px] h-[5px] bg-[#f0808050] rounded-full top-[60px] left-0 animate-shadow324"></div>
        <div className="absolute w-full h-full bg-[#f08080] rounded-[4px] animate-jump7456"></div>
      </div>
    </section>
  );
}
