import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 text-black rounded-lg shadow-2xl m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>focusinstitution.xyz!</b>
        </p>
        <p className="my-3">
          At focusinstitution.xyz, we are dedicated to providing top-quality
          educational resources and programs to help students and professionals
          achieve their academic and career goals. Our mission is to create a
          supportive and enriching learning environment that fosters growth and
          success.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, focusinstitution.xyz was established to bridge the
          gap between traditional education and modern learning needs. We
          believe in the power of education to transform lives and are committed
          to making high-quality learning accessible to all.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team consists of experienced educators, industry professionals,
          and technologists who work collaboratively to design and deliver
          exceptional learning experiences. We are passionate about education
          and dedicated to supporting our learners.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Online Courses:</b> A wide range of courses designed to meet the
            needs of students and professionals.
          </li>
          <li className="my-2">
            <b>Certification Programs:</b> Accredited programs to help learners
            gain industry-recognized qualifications.
          </li>
          <li className="my-2">
            <b>Support Services:</b> Comprehensive support services to assist
            learners throughout their educational journey.
          </li>
        </ul>

        <h6 className="font-bold">Get In Touch</h6>
        <p className="my-2">
          We love hearing from our community! If you have any questions,
          feedback, or just want to chat about education, feel free to reach out
          to us at contact@focusinstitution.xyz.
        </p>
        <p className="my-2">
          Thank you for choosing focusinstitution.xyz as your educational
          partner. We are committed to helping you achieve your goals.
        </p>
      </section>
    </>
  );
}
